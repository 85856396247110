import React from "react";

import HelpCenterItem from "../../containers/HelpCenter/HelpCenterItem";

const HelpCenterArticle = ({ path }) => {
  return (
    <HelpCenterItem path={path}>
      <div>
        <p>Make sure “Mute” switch is turned off in Settings.</p>
        <p>
          For Android devices, check Media volume.{" "}
          <a href="https://support.google.com/android/answer/9082609?hl=en">
            https://support.google.com/android/answer/9082609?hl=en
          </a>
        </p>
      </div>
    </HelpCenterItem>
  );
};

export default HelpCenterArticle;
